@use "sass:map";
@use "sass:math";

$colors: (
    bankOne-red: hsl(356, 85%, 55%),
    bankOne-red65: hsl(357, 100%, 22%),
    bankOne-grey: hsl(205, 5%, 54%),
);

$sizes: (
    xs: 1rem,
    sm: 1.5rem,
    md: 2rem,
    lg: 2.5rem,
    xl: 3rem,
    xxl: 3.5rem,
);

$UniversLTStd: (
    light: "UniversLTStd-Light",
    regular: "UniversLTStd-Regular",
    bold: "UniversLTStd-Bold",
);

//font
$font-light: map.get($UniversLTStd, light), Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
$font-regular: map.get($UniversLTStd, regular), Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
$font-bold: map.get($UniversLTStd, bold), Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
$font-family: $font-light;
$fs-xxl: var(--fs-xxl);
$fs-xl: var(--fs-xl);
$fs-md: var(--fs-md);
$fs-sm: var(--fs-sm);
$fs-xs: var(--fs-xs);
//spacing
$space: var(--space-size);
//colors
$color-red: var(--color-red);
$color-dark-red: var(--color-dark-red);
$color-grey: var(--color-grey);
$color-dark: var(--color-dark);
$color-brown: var(--color-brown);

//navbar
$navbar-height: var(--nav-height);

//media-screea

$max-sm: 575.98px;
$min-sm: 576px;
$max-md: 767.98px;
$min-md: 768px;
$max-lg: 991.98px;
$min-lg: 992px;
$max-xl: 1199.98px;
$min-xl: 1200px;
$min-xxl: 1400px;
$max-xxl: 1399.98px;
