%margin-0 {
    margin: 0;
}

%first-letter-capital {
    &:first-letter {
        text-transform: uppercase;
    }
}

%sup-vertical-align {
    sup {
        vertical-align: sub;
    }
}

%rows-parent-containers {
    overflow-x: hidden;
}

%rows {
    display: flex;
    // gap: 1.5rem;
    justify-content: space-between;

    @media (max-width: $max-sm) {
        flex-direction: column;
    }
}

%columns {
    @include for-size(lg) {
        flex: 0 0 50%;
    }
}

%columns-article {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-block-start: 1.5em;

    @include for-size(md) {
        margin-block-start: 0;
    }

    &>p.body {
        width: 100%;
        // max-width: 45ch;
        // @media (max-width: $max-sm) {
        //     min-width: 100%;
        //     text-align: justify;
        // }
    }

    @media (max-width: $max-sm) {
        flex: 0 0 65%;
    }

    @media (max-width: $max-lg) {
        flex: 0 0 60%;
    }
}

%columns-image {
    display: flex;

    @media (max-width: $max-sm) {
        overflow: hidden;
    }

    img {
        object-fit: cover;
        @include filter(drop-shadow(-5px 15px 15px rgba(0, 0, 0, 0.555)));

        @media (min-width: 500px) and (max-width: $max-sm) {
            width: 70%;
            margin-inline: auto;
        }

        @include for-size(sm) {
            margin-block-start: calc($navbar-height * -0.5);
            margin-block-end: -1.5em;
        }
    }
}

section#cover {
    margin-block-start: calc($navbar-height * -1);
    width: 100vw;
    height: 100vh;
    z-index: -2;
    background: $color-dark;

    // @include transition(opacity 0.3s ease-in-out);
    .slider {
        position: relative;
        height: 100vh;

        margin-block-start: calc($navbar-height * -1);
        margin-inline: calc($space * -1);

        &-item {
            position: absolute;
            color: #fff;
            width: 100%;
            height: inherit;
            z-index: 3;
            display: flex;
            align-items: center;
        }

        .hide {
            opacity: 0;
            @include transition(opacity ease-in 0.7s);

            .slider-title {
                @include transition(transform ease-in-out 0.7s 0.3s);
                @include transform(translateX(-100vw));
            }

            .slider-caption {
                @include transition(transform ease-in-out 0.7s 0.4s);
                @include transform(translateX(-100vw));
            }

            .slider-info {
                @include transition(transform ease-in-out 0.7s 0.5s);
                @include transform(translateX(-100vw));
            }
        }

        .show {

            .slider-title,
            .slider-caption,
            .slider-info {
                @include transform(translateX(0));
            }

            opacity: 1;
        }

        &-content {
            margin: 0;
        }

        &-image {
            object-fit: cover;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            @include filter(brightness(0.7));
        }

        &-title {
            font-size: $fs-xxl;
            font-family: $font-bold;
            text-transform: uppercase;
            @extend %sup-vertical-align;
            @extend %margin-0;
            // @include transition(transform ease-in-out 0.5s, opacity ease-in-out 0.5s);
        }

        &-body {
            z-index: 2;
            position: relative;
            padding-inline-start: $space;
        }

        &-caption {
            font-size: $fs-sm;
            @extend %margin-0;
            // @extend %first-letter-capital;
        }

        &-info {
            font-size: $fs-xs;
            @extend %sup-vertical-align;
            @extend %margin-0;
            @extend %first-letter-capital;
        }
    }

    #ubuntu {
        position: absolute;
        z-index: 6;
        inset-block: 0;
        right: 0;
        width: 30%;
        margin-inline-end: calc($space + 2rem);
        @include filter(opacity(0.5));

        svg * {
            fill: $color-red;
        }

        display: none;

        @include for-size(md) {
            display: block;
        }
    }
}

section#statement {
    p {
        font-size: $fs-md;

        @include for-size(md) {
            font-size: $fs-xl;
        }
    }

    .clip-text {
        background: linear-gradient(to right, $color-dark-red 50%, #fff 50%);
        background-size: 200%;
        background-repeat: none;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        display: inline-block;
        // font-size: clamp(1rem, 5vw, 3rem);
        margin-inline-end: 0.135em;
        // @include transition(all ease-in-out 0.3s);
    }
}

section#CEO_report {
    @extend %rows-parent-containers;

    .row {
        @extend %rows;

        @media (max-width: $max-lg) {
            flex-direction: column-reverse;
            margin-inline-end: 0;
        }

        // @media (min-width: $min-sm) and (max-width: $max-lg) {
        //     margin-inline-end: calc($space * -1);
        // }
    }

    .column {
        @extend %columns;

        iframe {
            width: 100%;
            height: auto;
            aspect-ratio: 16/9;
        }
    }

    article.column {
        @extend %columns-article;
        flex: 0 0 45%;

        @media (max-width: $max-lg) {
            margin-top: 2em;
        }

        @media (max-width: $max-sm) {
            min-height: auto !important;
        }
    }

    #first {
        display: block;
        margin-block-end: 1em;

        @include for-size(md) {
            display: none;
        }
    }

    #second {
        display: none;

        @include for-size(md) {
            display: block;
        }
    }

    // div.column {
    //     @extend %columns-image;

    //     @include for-size(sm) {
    //         margin-inline-end: calc($space * -1);
    //     }
    // }
}

section#chairman_report {
    @extend %rows-parent-containers;

    #sandra-martyres-signature {
        width: 100px;
        height: auto;
    }

    #sandra-martyres-article {
        color: transparent;
    }

    .row {
        @extend %rows;

        @media (min-width: $min-sm) and (max-width: $max-lg) {
            margin-inline-start: calc($space * -1);
        }
    }

    .column {
        @extend %columns;
    }

    article.column {
        @extend %columns-article;
    }

    div.column {
        @extend %columns-image;

        @include for-size(sm) {
            margin-inline-start: calc($space * -1);
        }
    }
}

%row-2-column {
    @include for-size(sm) {
        display: flex;
        // padding-block: $space calc($space * 0.5);
        padding-block: $space $navbar-height;
    }
}

%icon-display {
    display: flex;
    align-items: center;

    div:first-child {
        flex: 0 0 20%;
    }

    div:last-child {
        flex: 0 0 80%;
    }

    h3.heading {
        color: $color-dark-red;
        text-transform: uppercase;
    }

    .icon svg * {
        fill: $color-red;
    }
}

#our_values {
    margin: auto;
}

section#at_a_glance {
    div.row {
        @extend %row-2-column;

        article.icon-display {
            @include for-size(sm) {
                &:not(:last-child) {
                    padding-inline-end: calc($space * 0.5);
                }

                &:not(:first-child) {
                    padding-inline-start: calc($space * 0.5);
                }
            }
        }
    }

    article.icon-display {
        padding-block: 1em;
        @extend %icon-display;
    }

    #our_values {
        @media (min-width: $min-sm) {
            width: 50%;
        }
    }

    div.grid {
        margin-block-start: calc($space * 0.25);
        display: grid;
        gap: 2rem;

        @media (min-width: $min-sm) {
            grid-template-columns: repeat(2, 1fr);
        }

        h4.heading {
            color: $color-red;
        }
    }

    .offset-container {
        padding-block-start: $navbar-height;
        padding-block-end: $navbar-height;
    }
}

section#financial_highlights {

    // background: rgb(245, 245, 245);
    .title {
        margin-block-end: 1em;
    }

    div.grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 3rem;

        @include for-size(md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

section#from_africa_for_africa {
    h3.heading {
        color: $color-dark-red;
        text-transform: uppercase;
        margin-block-end: 1em;
    }

    h4.heading {
        span {
            width: 1em;
            display: inline-block;
            vertical-align: sub;
        }

        &:hover {
            cursor: pointer;
        }

        &:first-child {
            color: $color-red;
            margin-block-end: 1em;
        }

        &:last-child {
            color: $color-dark-red;

            span svg * {
                fill: $color-dark-red  !important;
            }
        }
    }

    .line {
        clip-path: inset(0 0 0 100%);
        @include transition(clip-path ease-in-out 0.5s);

        &.active {
            clip-path: inset(0 0 0 0);
        }
    }

    @include for-size(md) {
        .row {
            display: flex;
        }

        .map {
            flex: 0 0 60%;
            display: flex;
            justify-content: center;

            svg {
                width: 70%;
            }
        }

        .column {
            &:last-child {
                flex: 0 0 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}