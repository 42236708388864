%background-red {
    background-color: $color-red;
}

%background-brown {
    background-color: $color-brown;
}

main#leadership {
    #board-of-directors-list,
    #senior-management-teams-list {
        list-style: none;
        padding: 0;

        .list {
            &-item {
                padding-block: 0.385em;

                &:not(:last-child) {
                    border-bottom: rgb(36 36 36 / 10%) solid 0.1em;
                }
            }

            &-link {
                text-decoration: none;

                span {
                    display: block;
                    color: #242424;

                    &:nth-child(1) {
                        font-size: large;
                        font-family: $font-bold;
                        color: $color-red;
                        text-transform: uppercase;
                    }

                    &:nth-child(2) {
                        font-size: medium;
                        font-family: $font-regular;
                        // text-transform: capitalize;
                    }

                    &:nth-child(3) {
                        font-size: small;
                        // text-transform: capitalize;
                    }
                }
            }
        }

        @include for-size(md) {
            display: none;
        }
    }
    section#board-of-directors {
        div.slider-area {
            display: none;
            position: relative;
            @include for-size(md) {
                display: flex;
            }

            button {
                &.button {
                    position: absolute;
                    inset-block: 0;
                    width: $space;
                    background-color: #fff;
                    color: $color-dark-red;
                    @include border-radius(0);
                    &:hover {
                        color: $color-red;
                    }
                }

                &#slide {
                    &-left {
                        inset-inline-start: 5px;
                        // margin-inline-start: calc($space * -1);
                    }

                    &-right {
                        inset-inline-end: 5px;
                        // margin-inline-end: calc($space * -1);
                    }
                }
            }
        }

        div.slider {
            // margin-inline: calc($space * -1);
            margin-block-start: 0.5rem;
            display: none;
            // min-height: calc(100vh - 5rem);
            // scroll-behavior: smooth;
            white-space: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;

            width: calc(100% - ($space * 2));
            margin-inline: auto;

            @include for-size(md) {
                display: flex;
            }
        }

        .item {
            position: relative;
            text-align: center;

            min-width: 33.33%;
            aspect-ratio: 1/1;
            display: inline-block;
            // margin-inline: 1.5px;
            padding-inline: 1.5px;
            white-space: normal;
            text-decoration: none;

            @include transition(filter ease-in-out 0.5s);

            &-image {
                position: absolute;
                inset: 0;
                z-index: 0;
                padding-block-start: 1em;
                margin-inline: auto;
                @include transition(filter ease-in-out 0.3s);
                @include filter(drop-shadow(0 0 0 rgba(71, 40, 40, 0.75)));
            }

            &-color {
                color: #fff;

                @for $i from 0 to 13 {
                    @if $i % 2==1 {
                        &#data-#{$i} {
                            figure {
                                @extend %background-brown;
                            }
                        }
                    }

                    @if $i % 2==0 {
                        &#data-#{$i} {
                            figure {
                                @extend %background-red;
                            }
                        }
                    }
                }
            }

            figure {
                background-blend-mode: multiply;
                background-image: url("../resources/images/background-texture.jpg");

                margin: 0;
                display: flex;
                align-items: flex-end;
                height: 100%;
                position: relative;
                // padding-inline: calc($space - 0.5em);
                padding-block-start: 1.5em;

                &:hover {
                    figcaption {
                        @include transform(translateY(0%));
                    }

                    .item-image {
                        @include filter(drop-shadow(-20px 12px 34px rgb(36 36 36 / 75%)));
                    }
                }
            }

            figcaption {
                width: 100%;
                z-index: 1;
                padding-inline: calc($space - 0.5em);
                padding-block: 1em;

                background-color: $color-red;

                @include transition(transform 0.3s 0.3s ease-in-out);
                @include transform(translateY(100%));
            }

            span {
                display: block;
                text-align: start;

                &:first-child {
                    font-family: $font-bold;
                    text-transform: uppercase;
                }

                &:nth-child(2) {
                    font-family: $font-regular;
                    text-transform: capitalize;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    font-family: $font-light;
                    font-size: medium;
                }
            }

            @include for-size(md) {
                figcaption {
                    padding-inline: calc($space - 1.5em);
                }
            }

            @include for-size(lg) {
                @include filter(brightness(60%));

                &:hover {
                    @include filter(brightness(90%));
                }
            }

            // &-color {
            //     &:nth-child(2n-1) {
            //         background-color: #d1bdb3;
            //         color: #fff;
            //     }
            //     &:nth-child(2n),
            //     &:nth-child(13),
            //     &:nth-child(15) {
            //         background-color: $color-red;
            //         color: #fff;
            //     }
            //     &:nth-child(14),
            //     &:nth-child(16) {
            //         background-color: #d1bdb3;
            //         color: #fff;
            //     }
            // }
        }
    }

    section#senior-management-teams-profile {
        div#senior-management-teams-img {
            display: none;
            @include for-size(md) {
                display: block;
            }
        }

        g.button-svg {
            font-size: 16px;

            &:hover {
                rect.button-svg-background {
                    fill: $color-dark-red;
                }

                path.button-svg-arrow {
                    fill: #fff;
                    @include transform(translate(0.5em, 0.25em));
                }
            }
        }

        rect.button-svg-background {
            fill: $color-red;
            width: 2em;
            height: 2em;
            @include transition(fill ease-in-out 0.3s);
        }

        path.button-svg-arrow {
            fill: #fff;
            @include transition(transform ease-in-out 0.3s);
            @include transform(translate(0.25em, 0.25em));
        }

        .button-tool-tip {
            width: 1.5rem;
            transition: (all ease-in-out 0.3s);

            &:hover {
                width: 250px;
            }

            div.button-arrow {
                background-color: $color-red;
                width: 1.5rem;
                height: 1.5rem;
                text-align: center;
                color: #fff;

                i {
                    vertical-align: sub;
                }

                // position: relative;
                // &:after {
                //     content: "e0a0";
                //     position: absolute;
                //     inset: 0;
                //     margin: auto;
                // }
            }

            div.button-name {
                overflow: hidden;
                width: 0px;
                text-transform: capitalize;
                transition: (all ease-in-out 0.3s);

                p {
                    width: 100%;
                    padding: 0.75em;
                    background-color: #fff;
                    white-space: nowrap;
                    font-size: 0.85em;
                }
            }

            div.button-svg-background {
                width: fit-content;

                &:hover {
                    div.button-name {
                        width: 100%;
                    }
                }
            }
        }

        #ranjeevesingh {
            div.button-name p.body {
                max-width: 100px;
                white-space: normal;
            }
        }
    }
}

main#view {
    @media (max-width: $max-md) {
        overflow: hidden;
    }
    .container,
    .wrapper {
        padding: 0;
    }

    & > section.wrapper {
        @include for-size(sm) {
            margin-block-start: calc($navbar-height * -1);
        }

        & > .container {
            @include for-size(sm) {
                height: 100vh;
            }

            & > .row {
                @include for-size(sm) {
                    display: flex;
                    justify-content: space-between;
                    height: inherit;
                    position: fixed;
                }

                .column {
                    flex: 0 0 50%;
                }

                div.column {
                    display: flex;
                    background-blend-mode: multiply;

                    img {
                        object-fit: cover;
                        object-position: bottom;
                        height: 100%;
                        @include filter(drop-shadow(-20px 20px 20px rgb(36 36 36 / 75%)));
                    }
                }

                article.column {
                    background-color: #fff;
                    padding-inline: $space;
                    padding-block-start: calc($navbar-height * 0.5);

                    @include for-size(sm) {
                        padding-block-start: $navbar-height;
                    }

                    overflow-y: scroll;
                    overflow-x: hidden;

                    & > div {
                        padding-block-start: 1em;
                    }

                    h2.title {
                        color: $color-red;
                    }

                    // h3.heading {
                    //     text-transform: capitalize;
                    // }

                    .body {
                        @include for-size(lg) {
                            font-size: 1.3em;
                        }
                    }
                }
            }
        }
    }

    section#senior-management-teams {
        div.column {
            background-image: url("../resources/images/background-texture.jpg");

            @for $i from 0 to 12 {
                @if $i % 2==0 {
                    &#data-#{$i} {
                        @extend %background-brown;
                    }
                }

                @if $i % 2==1 {
                    &#data-#{$i} {
                        @extend %background-red;
                    }
                }
            }
        }
    }

    section#board-of-directors {
        div.column {
            background-image: url("../resources/images/background-texture.jpg");

            @for $i from 0 to 13 {
                @if $i % 2==1 {
                    &#data-#{$i} {
                        @extend %background-brown;
                    }
                }

                @if $i % 2==0 {
                    &#data-#{$i} {
                        @extend %background-red;
                    }
                }
            }
        }
    }
}

.navbar-carousel {
    z-index: 996;
    width: 100%;
    position: relative;
    padding-bottom: 2em;
    @include transition(height ease-in-out 0.3s, background-color ease-in-out 0.3s);

    .navbar-list {
        @extend %unlist-style;
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: var(--list-height);
        padding: 1em;
    }
    .slider-nav-buttons {
        display: none;
        &.active {
            display: flex;
            justify-content: space-evenly;
        }
        // position: relative;
        // .button {
        //     position: absolute;
        //     top: 0;
        //     bottom: 0;
        //     &#slide-left {
        //         left: 0;
        //     }
        //     &#slide-right {
        //         right: 0;
        //     }
        // }
    }

    .navbar-scroll {
        display: flex;
        overflow-x: scroll;
        position: absolute;
        bottom: -100vh;
        overflow-y: hidden;
        margin-bottom: 2em;
        @include transition(bottom ease-in-out 0.5s, position ease-in-out 0.5s);
        .item {
            flex: 0 0 33%;
            height: fit-content;
            figcaption {
                padding-inline: 1em !important;
            }

            span {
                font-size: small;
                line-height: 1.2;
                display: inline-block;
                text-align: center !important;
            }

            @include filter(brightness(1) !important);

            @for $i from 0 to 13 {
                @if $i % 2==1 {
                    &#data-#{$i} {
                        @extend %background-brown;
                    }
                }

                @if $i % 2==0 {
                    &#data-#{$i} {
                        @extend %background-red;
                    }
                }
            }
        }

        .item {
            position: relative;
            text-align: center;

            min-width: 33.33%;
            aspect-ratio: 1/1;
            display: inline-block;
            margin-inline: 1.5px;

            white-space: normal;
            text-decoration: none;

            background-blend-mode: multiply;
            background-image: url("../resources/images/background-texture.jpg");

            @include transition(filter ease-in-out 0.5s);

            &-image {
                position: absolute;
                inset: 0;
                z-index: 0;
                padding-block-start: 1em;
                margin-inline: auto;
                @include transition(filter ease-in-out 0.3s);
                @include filter(drop-shadow(0 0 0 rgba(71, 40, 40, 0.75)));
            }

            &-color {
                color: #fff;

                @for $i from 0 to 13 {
                    @if $i % 2==1 {
                        &#data-#{$i} {
                            @extend %background-brown;
                        }
                    }

                    @if $i % 2==0 {
                        &#data-#{$i} {
                            @extend %background-red;
                        }
                    }
                }
            }

            figure {
                margin: 0;
                display: flex;
                align-items: flex-end;
                height: 100%;
                position: relative;
                // padding-inline: calc($space - 0.5em);
                padding-block-start: 1.5em;

                &:hover {
                    figcaption {
                        @include transform(translateY(0%));
                    }

                    .item-image {
                        @include filter(drop-shadow(-20px 12px 34px rgb(36 36 36 / 75%)));
                    }
                }
            }

            figcaption {
                width: 100%;
                z-index: 1;
                padding-inline: calc($space - 0.5em);
                padding-block: 1em;

                background-color: $color-red;

                @include transition(transform 0.3s 0.3s ease-in-out);
                @include transform(translateY(100%));
            }

            span {
                display: block;
                text-align: start;

                &:first-child {
                    font-family: $font-bold;
                    text-transform: uppercase;
                }

                &:nth-child(2) {
                    font-family: $font-regular;
                    text-transform: capitalize;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    font-family: $font-light;
                    font-size: medium;
                }
            }

            @include for-size(md) {
                figcaption {
                    padding-inline: calc($space - 1.5em);
                }
            }

            @include for-size(lg) {
                @include filter(brightness(60%));

                &:hover {
                    @include filter(brightness(90%));
                }
            }

            // &-color {
            //     &:nth-child(2n-1) {
            //         background-color: #d1bdb3;
            //         color: #fff;
            //     }
            //     &:nth-child(2n),
            //     &:nth-child(13),
            //     &:nth-child(15) {
            //         background-color: $color-red;
            //         color: #fff;
            //     }
            //     &:nth-child(14),
            //     &:nth-child(16) {
            //         background-color: #d1bdb3;
            //         color: #fff;
            //     }
            // }
        }

        @include for-size(md) {
            width: 100%;
            margin-inline: auto;
        }
    }

    // bottom: calc(-15vh - var(--list-height));
    bottom: 0;
    // margin-bottom: 1em;
    @include transition(bottom ease-in-out 0.5s);

    &.active {
        height: auto;
        bottom: 0;
        background-color: rgb(0 0 0 /50%);

        .navbar-scroll {
            height: 100%;
            position: relative;
            bottom: 0;
        }
    }

    @include for-size(sm) {
        position: fixed;
        max-width: 50vw;
    }
}

#all-directors {
    width: 7.5em;
}
