@use "sass:math";

@mixin filter($value) {
    -webkit-filter: $value;
    -moz-filter: $value;
    -o-filter: $value;
    filter: $value;
}

//Fluid typography
@function strip-unit($value) {
    @return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {
            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) {
                font-size: calc(
                    #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
                        ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
                );
            }

            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

// HEADINGS
@mixin all-headings {
    @for $index from 1 through 6 {
        h#{$index} {
            @content;
        }
    }
}

// ADDS A BROWSER PREFIX TO THE PROPERTY
@mixin css3-prefix($property, $value) {
    -webkit-#{$property}: #{$value};
    -khtml-#{$property}: #{$value};
    -moz-#{$property}: #{$value};
    -ms-#{$property}: #{$value};
    -o-#{$property}: #{$value};
    #{$property}: #{$value};
}

@mixin bg_gradient($primary_gradient: #cedce7, $secondary_gradient: #596a72, $default: #cedce7) {
    background: $primary_gradient;
    background: -moz-linear-gradient(180deg, $primary_gradient 0%, $secondary_gradient 100%);
    background: -webkit-linear-gradient(180deg, $primary_gradient 0%, $secondary_gradient 100%);
    background: linear-gradient(180deg, $primary_gradient 0%, $secondary_gradient 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="##{$primary_gradient}",endColorstr="#{$secondary_gradient}",GradientType=1);}

// BACKGROUND GRADIENT
@mixin background-gradient($startColor: #3c3c3c, $endColor: #999999) {
    background: $startColor;
    background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
    background: -webkit-linear-gradient(top, $startColor, bottom, $endColor);
    background: -moz-linear-gradient(top, $startColor, bottom, $endColor);
    background: -ms-linear-gradient(top, $startColor, bottom, $endColor);
    background: -o-linear-gradient(top, $startColor, bottom, $endColor);
    background: linear-gradient(top, $startColor, bottom, $endColor);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

// BACKGROUND HORIZONTAL
@mixin background-horizontal($startColor: #3c3c3c, $endColor: #999999) {
    background-color: $startColor;
    background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
    background-image: -webkit-linear-gradient(left, $startColor, $endColor);
    background-image: -moz-linear-gradient(left, $startColor, $endColor);
    background-image: -ms-linear-gradient(left, $startColor, $endColor);
    background-image: -o-linear-gradient(left, $startColor, $endColor);
    background-image: linear-gradient(left, $startColor, $endColor);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}', gradientType='1');
}

// BACKGROUND RADIAL
@mixin background-radial($startColor: #ffffff, $startPos: 0%, $endColor: #000000, $endPos: 100%) {
    background: -moz-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
    background: -webkit-gradient(
        radial,
        center center,
        0px,
        center center,
        100%,
        color-stop($startPos, $startColor),
        color-stop($endPos, $endColor)
    );
    background: -webkit-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
    background: -o-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
    background: -ms-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
    background: radial-gradient(ellipse at center, $startColor $startPos, $endColor $endPos);
}

// BACKGROUND SIZE
@mixin background-size($width: 100%, $height: $width) {
    @if type-of($width) == "number" and $height !=null {
        @include css3-prefix("background-size", $width $height);
    } @else {
        @include css3-prefix("background-size", $width);
    }
}

// BACKGROUND COLOR OPACITY
@mixin background-opacity($color: #000, $opacity: 0.85) {
    background: $color;
    background: rgba($color, $opacity);
}

// BORDER RADIUS
@mixin border-radius($radius: 5px) {
    @include css3-prefix("border-radius", $radius);
}

@mixin border-radius-separate(
    $topLeftRadius: 5px,
    $topRightRadius: 5px,
    $bottomLeftRadius: 5px,
    $bottomRightRadius: 5px
) {
    -webkit-border-top-left-radius: $topLeftRadius;
    -webkit-border-top-right-radius: $topRightRadius;
    -webkit-border-bottom-right-radius: $bottomRightRadius;
    -webkit-border-bottom-left-radius: $bottomLeftRadius;

    -moz-border-radius-topleft: $topLeftRadius;
    -moz-border-radius-topright: $topRightRadius;
    -moz-border-radius-bottomright: $bottomRightRadius;
    -moz-border-radius-bottomleft: $bottomLeftRadius;

    border-top-left-radius: $topLeftRadius;
    border-top-right-radius: $topRightRadius;
    border-bottom-right-radius: $bottomRightRadius;
    border-bottom-left-radius: $bottomLeftRadius;
}

// BOX
@mixin box($orient: horizontal, $pack: center, $align: center) {
    display: -webkit-box;
    display: -moz-box;
    display: box;

    @include css3-prefix("box-orient", $orient);
    @include css3-prefix("box-pack", $pack);
    @include css3-prefix("box-align", $align);
}

// BOX RGBA
@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3c3c3c) {
    background-color: transparent;
    background-color: rgba($r, $g, $b, $opacity);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}', endColorstr='#{$color}');
    zoom: 1;
}

// BOX SHADOW
@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, 0.4), $inset: "") {
    @if ($inset != "") {
        @include css3-prefix("box-shadow", $inset $x $y $blur $color);
    } @else {
        @include css3-prefix("box-shadow", $x $y $blur $color);
    }
}

// BOX SIZING
@mixin box-sizing($type: border-box) {
    @include css3-prefix("box-sizing", $type);
}

// COLUMNS
@mixin columns($count: 3, $gap: 10) {
    @include css3-prefix("column-count", $count);
    @include css3-prefix("column-gap", $gap);
}

// DOUBLE BORDERS
@mixin double-borders($colorOne: #3c3c3c, $colorTwo: #999999, $radius: 0) {
    border: 1px solid $colorOne;

    @include css3-prefix("box-shadow", 0 0 0 1px $colorTwo);

    @include border-radius($radius);
}

// FLEX
@mixin flex($value: 1) {
    @include css3-prefix("box-flex", $value);
}

// FLIP
@mixin flip($scaleX: -1) {
    @include css3-prefix("transform", scaleX($scaleX));
    filter: FlipH;
    -ms-filter: "FlipH";
}

// FONT FACE

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_"),
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype",
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

// @mixin font-face(
//     $fontFamily: myFont,
//     $eotFileSrc: "myFont.eot",
//     $woffFileSrc: "myFont.woff",
//     $ttfFileSrc: "myFont.ttf",
//     $svgFileSrc: "myFont.svg",
//     $svgFontID: "#myFont"
// ) {
//     font-family: $fontFamily;
//     src: url($eotFileSrc) format("eot"), url($woffFileSrc) format("woff"), url($ttfFileSrc) format("truetype"),
//         url($svgFileSrc + $svgFontID) format("svg");
// }

// OPACITY
@mixin opacity($opacity: 0.5) {
    $opacityMultiplied: ($opacity * 100);

    filter: alpha(opacity=$opacityMultiplied);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
    @include css3-prefix("opacity", $opacity);
}

// OUTLINE RADIUS
@mixin outline-radius($radius: 5px) {
    @include css3-prefix("outline-radius", $radius);
}

// PARAGRAPH STYLE
@mixin paragraph-style($font-family, $font-size, $font-color, $letter-spacing, $line-height) {
    @if ($font-family !=null) {
        font-family: $font-family;
    }

    @if ($font-size !=null) {
        font-size: $font-size;
    }

    @if ($font-color !=null) {
        font-size: $font-color;
    }

    @if ($letter-spacing !=null) {
        font-size: $letter-spacing;
    }

    @if ($line-height !=null) {
        font-size: $line-height;
    }
}

// RESIZE
@mixin resize($direction: both) {
    @include css3-prefix("resize", $direction);
}

// ROTATE
@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
    @include css3-prefix("transform", rotate($deg + deg));
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
    zoom: 1;
}

// TEXT SHADOW
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, 0.4)) {
    text-shadow: $x $y $blur $color;
}

// TRANSFORM
@mixin transform($params) {
    @include css3-prefix("transform", $params);
}

// TRANSFORM-ORIGIN
@mixin transform-origin($params) {
    @include css3-prefix("transform-origin", $params);
}

// TRANSFORM STYLE
@mixin transform-style($style: preserve-3d) {
    @include css3-prefix("transform-style", $style);
}

// TRANSITION
@mixin transition($properties...) {
    @if length($properties) >=1 {
        @include css3-prefix("transition", $properties);
    } @else {
        @include css3-prefix("transition", "all 0.2s ease-in-out 0s");
    }
}

// TRIPLE BORDERS
@mixin triple-borders($colorOne: #3c3c3c, $colorTwo: #999999, $colorThree: #000000, $radius: 0) {
    border: 1px solid $colorOne;

    @include border-radius($radius);

    @include css3-prefix("box-shadow", "0 0 0 1px #{$colorTwo}, 0 0 0 2px #{$colorThree}");
}

// KEYFRAMES
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

// ANIMATION
@mixin animation($str...) {
    @include css3-prefix("animation", $str);
}

//Position
@mixin pos-absolute($positions...) {
    position: absolute;
    @include position($positions...);
}

@mixin pos-fixed($positions...) {
    position: fixed;
    @include position($positions...);
}

@mixin pos-relative($positions...) {
    position: relative;
    @include position($positions...);
}

@mixin position($positions...) {
    $positions: parseDirections($positions);
    top: map-get($positions, "top");
    right: map-get($positions, "right");
    bottom: map-get($positions, "bottom");
    left: map-get($positions, "left");
}

@function parseDirections($directions) {
    $top: 0;
    $bottom: 0;
    $left: 0;
    $right: 0;

    @if length($directions) >0 {
        $d1: nth($directions, 1);
        $top: $d1;
        $bottom: $d1;
        $left: $d1;
        $right: $d1;
    }

    @if length($directions) >1 {
        $d2: nth($directions, 2);
        $left: $d2;
        $right: $d2;
    }

    @if length($directions) >2 {
        $d3: nth($directions, 3);
        $bottom: $d3;
    }

    @if length($directions) >3 {
        $d4: nth($directions, 4);
        $left: $d4;
    }

    @return (top: $top, bottom: $bottom, left: $left, right: $right);
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

//MEDIA QUERIES
@mixin for-size($size) {
    @if $size==xs {
        @media (max-width: 575.98px) {
            @content;
        }
    } @else if $size==sm {
        @media (min-width: 576px) {
            @content;
        }
    } @else if $size==md {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $size==md-only {
        @media (max-width: 767.98px) {
            @content;
        }
    } @else if $size==lg {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $size==lg-only {
        @media (max-width: 1199.98px) {
            @content;
        }
    } @else if $size==xl {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $size==xxl {
        @media (min-width: 1400px) {
            @content;
        }
    } @else if $size==desktop-only {
        @media (min-aspect-ratio: 3/2) and (orientation: landscape) {
            @content;
        }
    }
}
