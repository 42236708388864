%nav_and_footer_fixed {
    position: fixed;
    z-index: 999;
    width: 100%;
    // color: #fff;
}

main {
    padding-top: $navbar-height;
}

header#navigation-bar.navbar {
    @extend %nav_and_footer_fixed;
    height: $navbar-height;
    inset-block-start: 0;

    & > div {
        height: inherit;
        display: flex;
        width: 100%;
        align-items: flex-end;
        padding-bottom: 0.75em;
        @include transition(background-color ease-in-out 0.3s, background ease-in-out 0.3s);
    }

    $navbar: "navbar";

    div.#{$navbar}-background-red {
        background-color: $color-red;
    }

    div.#{$navbar}-background-transparent {
        background-color: transparent;
    }

    ul.#{$navbar}-list {
        @extend %unlist-style;
        display: flex;
        align-items: center;
    }

    li.#{$navbar}-item {
        display: inline;
        text-transform: capitalize;
        color: #fff;
        font-size: $fs-sm;

        &:first-child {
            margin-inline-end: auto;

            svg {
                // width: clamp(180px, 10vw + 65px, 250px);
                $svg-logo-min-size: 180px;
                width: $svg-logo-min-size;

                @each $size, $value in $sizes {
                    @if $size !=xs {
                        $svg-logo-min-size: $svg-logo-min-size + 15;

                        @include for-size($size) {
                            width: $svg-logo-min-size;
                        }
                    }
                }

                display: block;
                margin-block-end: 0.285em;

                * {
                    @include transition(fill ease-in-out 0.3s);
                    fill: #fff;
                }
            }

            h1 {
                text-transform: capitalize;
                // font-size: clamp(1.005rem, 0.125rem + 1.125vw, 1.5rem);

                $logo-font-size: 16px;
                font-size: clamp(0.75em, 0.7ch + 0.5vw, 2ch);
                // font-size: 16px;
                // @each $size, $value in $sizes {
                //     $logo-font-size: $logo-font-size + 1;
                //     @if $size != xs {
                //         @include for-size($size) {
                //             font-size: $logo-font-size;
                //         }
                //     }
                // }

                margin: 0;
                white-space: nowrap;
            }
        }

        &:not(&:first-child, &:last-child) {
            margin-inline: 1em;
        }

        &:not(&:first-child, #navbar-toggler) {
            @extend %link-hover;
        }

        &:after:not(&:first-child, #navbar-toggler) {
            background: #fff;
        }

        &:hover {
            a {
                color: $color-dark-red;
            }

            svg * {
                fill: $color-dark-red;
            }

            &:after {
                background: $color-dark-red;
            }
        }
    }

    a.#{$navbar}-link {
        @include transition(color ease-in-out 0.3s);
        color: inherit;
        font-size: 0.75em;
    }

    li##{$navbar}-download {
        display: none;

        @include for-size(md) {
            display: inline-block;
        }
    }

    li##{$navbar}-toggler {
        aspect-ratio: 1/0.8;
        margin-inline-start: 0.5rem;
        width: 35px;
        @include transition(aspect-ratio ease-in-out 0.3s);

        button {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: transparent;
            padding: 0;
            border: none;

            div {
                background-color: #fff;
                height: 3px;
                width: 100%;
                @include transition(
                    background-color ease-in-out 0.3s,
                    transform ease-in-out 0.3s,
                    transform-origin ease-in-out 0.3s,
                    width ease-in-out 0.3s,
                    opacity ease-in-out 0.3s
                );
            }
        }

        &:hover {
            aspect-ratio: 1/1;

            button div {
                background-color: $color-dark-red;
            }
        }

        @include for-size(xs) {
            margin-inline-start: auto;
        }

        &.active {
            aspect-ratio: 1/1;

            div {
                @for $i from 1 through 3 {
                    &:nth-child(#{$i}) {
                        @if $i==1 or $i ==3 {
                            @if $i==1 {
                                @include transform(rotate(45deg));
                                @include transform-origin(left bottom);
                            } @else if $i==3 {
                                @include transform(rotate(-45deg));
                                @include transform-origin(left top);
                            }

                            width: 45px;
                        }

                        @if $i==2 {
                            @include transform(translateX(100%));
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    div.#{$navbar}-background-view {
        background: $color-red;

        @include for-size(sm) {
            background: linear-gradient(90deg, transparent 50%, #fff 50%);

            li.#{$navbar}-item {
                color: $color-red;

                h1 {
                    color: #fff;
                }
            }

            li##{$navbar}-toggler {
                button div {
                    background-color: $color-red;
                }
            }
        }
    }

    &.active {
        div.#{$navbar}-background-view {
            li.#{$navbar}-item a {
                color: #fff;
            }

            li##{$navbar}-toggler {
                button div {
                    background-color: #fff;
                }
            }
        }
    }

    /*&.navbar.active {
        li.#{$navbar}-item {
            &:hover {
                a {
                    color: $color-red;
                }
                svg * {
                    fill: $color-red;
                }
                &:after {
                    background: $color-red;
                }
            }
        }
        li##{$navbar}-toggler {
            &:hover {
                button div {
                    background-color: $color-red;
                }
            }
        }
    }**/
}

aside#menu-panel.menu {
    position: fixed;
    z-index: 998;
    inset: 0;
    background: $color-red;
    height: 100vh;
    overflow: hidden;
    opacity: 0;
    display: none;
    @include transition(opacity ease-in-out 0.3s);

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: inherit;
        gap: 1.5rem;
        padding-block-start: $navbar-height;
        margin-block-start: calc($navbar-height * -0.2);
    }

    $menu: "menu";

    ul.#{$menu}-list {
        color: #fff;
        z-index: 3;
        @extend %unlist-style;
    }

    li.#{$menu}-item {
        font-family: $font-bold;
        white-space: nowrap;
        font-size: calc($fs-md + 0.5em);
        @include for-size(md) {
            white-space: normal;
            font-size: $fs-xxl;
        }
        text-transform: uppercase;
        width: fit-content;

        &:hover a.#{$menu}-link {
            color: $color-dark-red;

            @include for-size(lg) {
                color: $color-red;
            }
        }
    }

    a.#{$menu}-link {
        color: inherit;
        font-size: 0.75em;
        @include transition(color ease-in-out 0.5s);
    }

    div.#{$menu}-background,
    div.#{$menu}-image-container {
        display: none;

        @include for-size(lg) {
            display: block;
        }
    }

    #ubuntu-menu {
        display: none;
    }

    @include for-size(lg) {
        div.#{$menu}-background {
            position: fixed;
            width: 100%;
            inset: -20% 0 0 -20%;
            height: inherit;
            margin-inline: calc($space * -1);
            opacity: 0.8;
            width: 150vw;
            mix-blend-mode: multiply;
            @include filter(grayscale(1) blur(30px) brightness(0.7));
        }

        img.#{$menu}-background-image {
            opacity: 0;
            position: absolute;
            z-index: 1;
            @include transition(opacity ease-in-out 0.5s);

            &.active {
                z-index: 2;
                opacity: 1;
            }
        }

        div.#{$menu}-image-container {
            flex: 0 0 40%;
            position: relative;
            z-index: 2;
            height: 100%;

            .menu-image {
                position: absolute;
                inset-block: 0;
                margin: auto;
                opacity: 0;
                height: fit-content;
                color: #fff;
                @include transition(opacity ease-in-out 0.5s);

                img {
                    @include filter(brightness(0.7));
                }

                figcaption {
                    margin-block: auto;
                    position: absolute;
                    inset-block: 0;
                    inset-inline-end: 0;
                    height: fit-content;
                    width: 40%;
                }

                &.active {
                    opacity: 1;
                }

                h3.heading {
                    text-transform: uppercase;
                    font-family: $font-bold;
                    margin: 0;
                }

                p {
                    font-size: $fs-sm;
                    margin: 0;
                    width: 70%;
                }
            }
        }

        #ubuntu-menu {
            display: block;
            position: absolute;
            z-index: 999;
            // inset-block: 0;
            margin-block: 0;
            right: 0;
            width: 10%;
            margin-inline-end: calc($space + 25%);
            @include filter(opacity(0.5));

            svg * {
                fill: $color-red;
            }
        }
    }
}

footer#social-media-footer.footer {
    @extend %nav_and_footer_fixed;
    inset-block-end: 0;
    display: flex;
    margin-inline-start: auto;
    padding-inline: $space;
    height: calc($navbar-height * 0.5);
    z-index: 998;
    width: auto;
    right: 0;

    $footer: "footer";

    ul.#{$footer}-list {
        display: flex;
        margin-inline-start: auto;
        @extend %unlist-style;
    }

    li.#{$footer}-item {
        padding-inline: 0.5em;
        margin-inline: 0.3em;
        font-size: 1.3em;
        color: $color-red;

        &:hover a.#{$footer}-link {
            color: $color-dark-red;

            i:after {
                background-color: $color-red;
            }
        }
    }

    a.#{$footer}-link {
        color: inherit;
        @include transition(color ease-in-out 0.3s);

        i {
            position: relative;

            &:after {
                content: "";
                display: inline-block;
                background-color: #fff;
                padding: 0.75em;
                border-radius: 100%;
                position: absolute;
                left: -1rem;
                top: -0.8rem;
                margin-left: 0.6rem;
                margin-top: 0.4rem;
                z-index: -1;
                @include transition(background-color ease-in-out 0.3s);
            }
        }
    }
}

footer#footer-main {
    background-color: #fff;
    padding-block: 1.5em;
    z-index: 999;

    a {
        color: $color-red;
        text-decoration: none;
        @include transition(color ease-in 0.3s);

        &:hover {
            cursor: pointer;
            color: $color-dark-red;
        }
    }
}
