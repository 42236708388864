%heading {
    text-transform: uppercase;
}

section#corporate-profile {
    table {
        width: 100%;
        padding-block-start: 1em;
    }
    table,
    th,
    td,
    tr {
        border-collapse: collapse;
    }
    .table-main {
        background-color: rgb(227, 227, 227);
        // padding: 1em;
        text-align: left;
        th {
            font-size: calc($fs-md * 0.7);
            font-family: $font-regular;
            text-transform: uppercase;
            color: $color-red;
            padding: 0.25em;
        }
        td {
            font-size: $fs-sm;
            font-family: $font-light;
            vertical-align: top;
        }
        th,
        td {
            padding-block: 0.325em;
            padding-inline: 1em;
        }
    }
    .table-name {
        text-transform: capitalize;
    }

    .table-wrapper {
        h3.heading {
            color: $color-grey;
            // font-size: $fs-md;
            font-family: $font-regular;
            margin-block-start: 2em;
            margin-block-end: 0.5em;
        }
    }

    #directors_in_office_during_the_financial_year_ended_31_december_2021 {
        h3.heading {
            color: $color-red !important;
        }

        td {
            border: #fff solid 2px;
        }
    }

    #board_committees_composition_as_at_31_december_2021 {
        tr.table-main-tr > td {
            padding: 0;
        }
        tr.table-main-tr {
            & > td.table-main-td:first-child {
                border-right: #fff solid 2px;
            }
        }
        table > tr > td {
            padding-block: 0.325em;
            padding-inline: 1em;
        }
    }

    #executive_management_team {
        td:not(:last-child) {
            border-right: #fff solid 2px;
        }
    }

    .table-hover:hover {
        cursor: pointer;
        background-color: $color-grey;
        color: #fff;
    }
}

section#corporate_governance_report {
    h2.title {
        margin-block-end: 0.6em;
    }
    h3.heading {
        @extend %heading;
        margin-block-end: 0.3em;
        color: $color-red;
    }
    div.body {
        margin: 0;
    }
    img {
        width: min(700px, 100%);
        margin: auto;
        display: block;
    }
}

section#relationship_with_shareholders_and_other_key_stakeholders {
    h3.heading {
        @extend %heading;
        margin-block-end: 0.3em;
        color: $color-red;
    }
    article.article-wrapper {
        display: flex;
        font-size: $fs-sm;
        width: 100%;
        flex-direction: column;
        border-start-start-radius: 1em;
        border-start-end-radius: 1em;
        background-color: rgb(227, 227, 227);
        &:not(:last-child) {
            margin-block-end: 1em;
        }
        @include for-size(md) {
            background: transparent;
            flex-direction: row;
            border-start-end-radius: 0em;
        }
        div {
            width: 100%;
            align-items: center;
            display: flex;
            padding: 1em;
        }
        div:first-child {
            flex: 0 0 10%;
            @include border-radius(1em);
            text-align: center;
            justify-content: center;
            min-width: 180px;
            @include for-size(md) {
                min-height: 180px;
            }
        }
        div:last-child {
            align-self: center;
            padding-block: 1em;
            min-height: 138px;
        }
    }
}

section#shareholding_structure_and_shareholders {
    h3.heading {
        @extend %heading;
        margin-block-end: 0.3em;
        color: $color-red;
    }
    img {
        width: min(700px, 100%);
        margin: auto;
        display: block;
    }
}

section#lines_of_business_and_digital_transformation {
    h3.heading {
        color: $color-red;
        text-transform: uppercase;
    }
    div.articles-wrapper {
        margin-block-start: 2em;
        article:not(:last-child) {
            margin-block-end: 2em;
        }
    }
    details > summary {
        list-style-type: none;
        outline: none;
        cursor: pointer;
        &::marker {
            display: none;
        }
        &:before {
            content: "+ ";
            font-size: $fs-sm;
            display: inline-block;
            vertical-align: super;
        }
        h3 {
            display: inline-block;
        }
    }
    details[open] > summary {
        margin-block-end: 0.5rem;
        &::before {
            content: "- ";
        }
    }
}

section#risk_management {
    h3.heading {
        color: $color-red;
        text-transform: uppercase;
    }
}
