%unlist-style {
    list-style: none;
    margin: 0;
    padding: 0;
    a {
        text-decoration: none;
    }
}

%wrapper {
    width: 100%;
    padding-block-end: 1.5em;
    position: relative;
    z-index: 5;

    & > .container {
        padding-block-start: $navbar-height;
    }

    &:not(#cover, #view section.wrapper) {
        & > .container {
            @media (max-width: $max-md) {
                padding-block-start: calc($navbar-height * 0.5);
            }
        }
    }
}

%link-hover {
    position: relative;
    @include transition(color ease-in-out 0.3s);

    &:after {
        content: "";
        @include position(absolute, null, 0, 0, 0);
        height: 0.1em;
        width: 0;
        @include transition(width ease-in-out 0.3s);
    }

    &:hover {
        &:after {
            width: 100%;
        }
    }
}

section.wrapper {
    @extend %wrapper;
    background-color: #fff;
    color: $color-dark;

    &-gradient {
        @extend %wrapper;
        @include bg_gradient($color-red, $color-dark-red 150%);

        color: #fff;

        h2.title {
            color: #fff;

            span {
                color: $color-dark-red;
            }
        }

        h3.heading {
            color: $color-dark;
        }

        p.body,
        div.body {
            color: #fff;
            a {
                color: $color-dark-red;
            }
        }
    }

    &-grey {
        @extend %wrapper;
        background: rgb(245, 245, 245);
    }
}

.container {
    width: inherit;
    padding-inline: $space;
}

h2.title {
    margin: 0;
    // color: $color-grey;
    color: $color-grey;
    font-size: $fs-xl;
    font-family: $font-light;
    text-transform: uppercase;
    line-height: normal;

    span {
        font-family: $font-regular;
        color: $color-red;
    }
}

h3.heading {
    font-size: $fs-md;
    margin: 0;
    text-transform: none;

    &::first-letter {
        text-transform: uppercase;
    }
}

h4.heading {
    font-size: calc($fs-md * 0.8);
    margin: 0;

    &::first-letter {
        text-transform: uppercase;
    }
}

p.body,
div.body {
    font-size: $fs-sm;
    font-family: $font-light;
    color: $color-grey;

    strong,
    b {
        font-family: $font-regular;
    }

    a {
        text-decoration: none;
        @extend %link-hover;
        color: $color-red;

        &:after {
            background: $color-red;
        }

        &:hover {
            color: $color-dark-red;

            &:after {
                background: $color-dark-red;
            }
        }
    }

    @at-root p.body {
        margin: 0;
    }

    @at-root div.body {
        margin-block-start: 1em;

        & > p {
            font-size: inherit;

            &:first-child {
                margin-block-start: 0;
            }

            &:last-child {
                margin-block-end: 0;
            }
        }
    }
}

.button {
    text-decoration: none;
    text-align: center;
    width: 12.5rem;
    background-color: $color-dark-red;
    color: #fff;
    font-size: $fs-sm;
    padding-inline: 0.5em;
    // padding-block: 0.3em;
    padding-block: 0.5em;
    @include transition(background-color ease-in-out 0.3s, color ease-in-out 0.3s);
    @include border-radius(0.15em);
    border: 0;

    &:hover {
        background-color: #fff;
        color: $color-red;
    }

    @at-root a.button {
        &:not(button.button):last-child {
            margin-block-start: 1em;
        }
    }
    &.lg{
        width: auto;
    }
}

button.button {
    line-height: 0;
}

#loading {
    @include bg_gradient($color-red, $color-dark-red 150%);
    height: 100vh;
    width: 100vw;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include transition(opacity 0.5s ease-in-out 0.5s);
    opacity: 7;
    position: fixed;
    z-index: 1002;

    &-text {
        color: inherit;
        font-family: $font-bold;
        text-align: center;
        text-transform: uppercase;
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 2rem;
        overflow-y: hidden;

        h2 {
            margin: 0;
            position: absolute;
            height: inherit;
            opacity: 2;
            font-size: $fs-md;
            @include transition(all ease-in-out 0.6s);
        }

        h2:not(.show) {
            transform: translateY(100%);
            opacity: 0;
        }
    }

    &-image {
        position: absolute;
        width: 30%;
        min-width: 250px;
        max-width: 500px;
        z-index: 0;
        // opacity: 0.1;

        svg * {
            fill: $color-red;
            // opacity: 1;
        }
    }

    &-image.intro {
        opacity: 0.5;

        svg * {
            opacity: 1;
        }
    }

    &.hide {
        opacity: 0;
    }

    &.preload {
        #loading-image {
            width: 15%;
            min-width: 60px;
            max-width: 100px;

            svg * {
                fill: #fff;
            }
        }
    }
}

.background-gradient-red {
    color: #fff;
    @include bg_gradient($color-red, $color-dark-red 150%);
}

.background-grey {
    background-color: rgb(227, 227, 227);
}

.offset-container {
    margin-inline: calc($space * -1);
    padding-inline: $space;
}

text,
tspan {
    font-family: $font-regular !important;
}

g[font-size="56"] {
    text,
    tspan {
        font-family: $font-light;
    }
}

ul.transition {
    @extend %unlist-style;
    display: flex;
    position: absolute;
    z-index: 1001;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    pointer-events: none;

    li {
        @include transform(scaleY(0));
        @include bg_gradient($color-red, $color-dark-red 150%);
        width: 20%;
    }
}
