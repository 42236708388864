:root {
    @each $media, $size in $sizes {
        @include for-size($media) {
            --space-size: #{map.get($sizes, $media) + 0.5};
        }
    }

    // font-size: 13px;
    // @include for-size(sm) {
    //     //576px
    //     font-size: 12px;
    // }
    // @include for-size(lg) {
    //     //992px
    //     font-size: 14px;
    // }
    // @include for-size(xxl) {
    //     //1400px
    //     font-size: 16px;
    // }

    --fs-xxl: #{clampViewport(991.98, 320, 4.5, 3.25)};
    --fs-xl: #{clampViewport(991.98, 320, 3.125, 3)};
    --fs-md: #{clampViewport(991.98, 320, 2, 1.625)};
    --fs-sm: #{clampViewport(991.98, 320, 1.5, 1.25)};
    --fs-xs: #{clampViewport(991.98, 320, 1.125, 1)};

    --color-red: #{map.get($colors, bankOne-red)};
    --color-dark-red: #{map.get($colors, bankOne-red65)};
    --color-grey: #{map.get($colors, bankOne-grey)};
    --color-dark: #242424;
    --color-brown: #d1bdb3;

    --nav-height: #{clampViewport(1199.98, 320, 7.5, 5.675)};
    @media (min-width: $min-xxl) and (min-width: $min-md) and (orientation: landscape) {
        --nav-height: #{clampViewport(1199.98, 320, 8.69775, 5.675)};
    }

    // --nav-height: calc(50.8px + 2.5rem);

    // @media (min-width: $min-sm) {
    //     --nav-height: calc(55.44px + 3rem);
    // }
    // @media (min-width: $min-md) {
    //     --nav-height: calc(58.82px + 4.5rem);
    // }
    // @media (min-width: $min-lg) {
    //     --nav-height: calc(63.18px + 4.5rem);
    // }
    // @media (min-width: $min-xl) {
    //     --nav-height: calc(67.17px + 4.5rem);
    // }
    // @media (min-width: $min-xxl) {
    //     --nav-height: calc(70.55px + 4.5rem);
    // }
    --list-height: 7rem;
    color: $color-dark;
    scroll-behavior: smooth;
}
